const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isadmin'] = require('../middleware/isadmin.js')
middleware['isadmin'] = middleware['isadmin'].default || middleware['isadmin']

middleware['isdebug'] = require('../middleware/isdebug.js')
middleware['isdebug'] = middleware['isdebug'].default || middleware['isdebug']

middleware['scrollToTop'] = require('../middleware/scrollToTop.js')
middleware['scrollToTop'] = middleware['scrollToTop'].default || middleware['scrollToTop']

export default middleware
