import Vue from 'vue'
import mapboxgl from "mapbox-gl";
import countries from "~/static/json/country-bounding-boxes.json";

Vue.mixin({
  methods: {
    newMap(containerId, gpsLongitude, gpsLatitude, zoomLevel, countryCode) {
      const mapboxAPIkey = process.env.MAPBOX_API_KEY
      //console.log("mapboxAPIkey", mapboxAPIkey)
      mapboxgl.accessToken = mapboxAPIkey;
      var map = new mapboxgl.Map({
        container: containerId,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [gpsLongitude, gpsLatitude],
        zoom: (typeof zoomLevel === 'undefined' ? 10 : zoomLevel),
        bounds: !gpsLongitude ? countries[countryCode][1] : null
      });
      map.doubleClickZoom.disable();
      return map;
    },

    focusMap(map, gpsLongitude, gpsLatitude, zoomLevel) { // map = mapboxgl.Map Object
      map.flyTo({
        center: [
          gpsLongitude, gpsLatitude
        ],
        zoom: (typeof zoomLevel === 'undefined' ? 12 : zoomLevel),
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    },

    addMarker(longitude, latitude, options=null ) { // options = { icon, htmlClass, id, status, object }
      let iconClass = options && options.icon ? options.icon : 'car'
      let htmlClass = options && options.htmlClass ? options.htmlClass : 'mapMarker'
      let id = options && options.id ? options.id : null
      let status = options && options.status ? options.status : null
      let object = options && options.object ? options.object : null

      var el = document.createElement('div');
      el.id = typeof id === 'undefined' ? '' : id;
      el.className = htmlClass+' pop-out'; // styling class

      /* create the icon */
      var icon = document.createElement('i');
      icon.className = 'fas fa-'+iconClass;
      el.appendChild(icon);

      var marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude])

      return {
        id: id,
        status: status,
        object: object,
        mapObject: marker,
        DOM_element: marker._element,
        longitude: longitude,
        latitude: latitude
      }
    },

    updateMarker(marker, longitude, latitude) { // marker = mapboxgl.MarkerObject
      marker.setLngLat([longitude, latitude]);
    },

    /*
    addMultipleMarkers(map, listOfMarkers) { // map = mapboxgl.Map Object    // list = list of markers
      let markers = [];
      $.each(listOfMarkers, function (key, marker) {
        markers.push(addMarker(map, marker.longitude, marker.latitude, marker.id));
      })
      return markers;
    }
    //*/

    flyToCountry(map, countryCode) {
      map.fitBounds(countries[countryCode][1], { padding: 30 });
    },

    deg2rad(deg) { //  Maths function to convert angles from Degres (°) to Radian (rad)
      return deg * (Math.PI/180)
    },
    
    getDistanceFromLatLonInKm(point1, point2) { // Method to calculate straigth-line distance between 2 coordoninates
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(point2.latitude-point1.latitude);  // deg2rad below
      var dLon = this.deg2rad(point2.longitude-point1.longitude); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(point1.latitude)) * Math.cos(this.deg2rad(point2.latitude)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      return d;
    }
  }

})
