import Vue from 'vue'
Vue.mixin({
  methods: {
    isLandingPhone(string){
        const regex = /^((\+)33|0)[1-9](\d{2}){4}$/g
        return regex.test(string);
    },
    isMobilePhone(string){
        const regex = /^((\+)33|0)[6-7](\d{2}){4}$/g
        return regex.test(string);
    },

    isName(string){
        const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        return string.length > 1 ? regex.test(string) : false;
    },

    isEmail(string){
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        return regex.test(string);
    },

    isStrongPassword(string){
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g
        return regex.test(string);
    },

    isPlateNumber(string){
        const regex = /^[a-zA-Z]{2}-?[0-9]{3}-?[a-zA-Z]{2}$/g
        return regex.test(string);
    },

    isVinNumber(string){
        const regex = /^[a-zA-Z0-9]{17}$/g
        return regex.test(string);
    },

    isIdentificationNumber(string){
        const regex = /^[a-zA-Z0-9]{0,50}$/g
        return regex.test(string);
    },
  }
})