//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      picture_default: 'https://images.vexels.com/media/users/3/135118/isolated/preview/676bf0e9f3c16649cd7f426c6dcd755a-flat-user-sign-with-round-background.png'
    }
  },
  props: {
    picture: {
      type: String,
      required: false
    }
  },
};
