import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4f7d7480 = () => interopDefault(import('../pages/cards/index.vue' /* webpackChunkName: "pages/cards/index" */))
const _4b83b83e = () => interopDefault(import('../pages/contracts/index.vue' /* webpackChunkName: "pages/contracts/index" */))
const _450f6030 = () => interopDefault(import('../pages/drivers/index.vue' /* webpackChunkName: "pages/drivers/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5c3f1c94 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _d613ed9e = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _d9f9f28e = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _8f584308 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _c67431cc = () => interopDefault(import('../pages/settings/account/index.vue' /* webpackChunkName: "pages/settings/account/index" */))
const _125576c4 = () => interopDefault(import('../pages/settings/oem/index.vue' /* webpackChunkName: "pages/settings/oem/index" */))
const _4afafd6c = () => interopDefault(import('../pages/settings/telematics/index.vue' /* webpackChunkName: "pages/settings/telematics/index" */))
const _527571b5 = () => interopDefault(import('../pages/settings/users/index.vue' /* webpackChunkName: "pages/settings/users/index" */))
const _7e42d4da = () => interopDefault(import('../pages/settings/oem/add.vue' /* webpackChunkName: "pages/settings/oem/add" */))
const _1a3f43e9 = () => interopDefault(import('../pages/settings/oem/smartcar.vue' /* webpackChunkName: "pages/settings/oem/smartcar" */))
const _3f64e0a2 = () => interopDefault(import('../pages/settings/oem/_id/index.vue' /* webpackChunkName: "pages/settings/oem/[_]id/index" */))
const _b000fb52 = () => interopDefault(import('../pages/settings/telematics/_id/index.vue' /* webpackChunkName: "pages/settings/telematics/[_]id/index" */))
const _fb82fea4 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _5df21fe1 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _5f297c20 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _3e22d24f = () => interopDefault(import('../pages/cards/add.vue' /* webpackChunkName: "pages/cards/add" */))
const _bc7a24e6 = () => interopDefault(import('../pages/contracts/add.vue' /* webpackChunkName: "pages/contracts/add" */))
const _346d67b7 = () => interopDefault(import('../pages/drivers/add.vue' /* webpackChunkName: "pages/drivers/add" */))
const _6d2a8429 = () => interopDefault(import('../pages/contracts/_id/index.vue' /* webpackChunkName: "pages/contracts/[_]id/index" */))
const _3e5736d3 = () => interopDefault(import('../pages/drivers/_id/index.vue' /* webpackChunkName: "pages/drivers/[_]id/index" */))
const _22b0f1ea = () => interopDefault(import('../pages/vehicles/_id/index.vue' /* webpackChunkName: "pages/vehicles/[_]id/index" */))
const _b506bac0 = () => interopDefault(import('../pages/contracts/_id/switch-card.vue' /* webpackChunkName: "pages/contracts/[_]id/switch-card" */))
const _412ae868 = () => interopDefault(import('../pages/contracts/_id/switch-vehicle.vue' /* webpackChunkName: "pages/contracts/[_]id/switch-vehicle" */))
const _97c9817e = () => interopDefault(import('../pages/vehicles/_id/doors/index.vue' /* webpackChunkName: "pages/vehicles/[_]id/doors/index" */))
const _ba2736e8 = () => interopDefault(import('../pages/vehicles/_id/doors/control.vue' /* webpackChunkName: "pages/vehicles/[_]id/doors/control" */))
const _437ed506 = () => interopDefault(import('../pages/vehicles/_id/doors/control-location.vue' /* webpackChunkName: "pages/vehicles/[_]id/doors/control-location" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cards",
    component: _4f7d7480,
    name: "cards"
  }, {
    path: "/contracts",
    component: _4b83b83e,
    name: "contracts"
  }, {
    path: "/drivers",
    component: _450f6030,
    name: "drivers"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/map",
    component: _5c3f1c94,
    name: "map"
  }, {
    path: "/password",
    component: _d613ed9e,
    name: "password"
  }, {
    path: "/settings",
    component: _d9f9f28e,
    children: [{
      path: "",
      component: _8f584308,
      name: "settings"
    }, {
      path: "account",
      component: _c67431cc,
      name: "settings-account"
    }, {
      path: "oem",
      component: _125576c4,
      name: "settings-oem"
    }, {
      path: "telematics",
      component: _4afafd6c,
      name: "settings-telematics"
    }, {
      path: "users",
      component: _527571b5,
      name: "settings-users"
    }, {
      path: "oem/add",
      component: _7e42d4da,
      name: "settings-oem-add"
    }, {
      path: "oem/smartcar",
      component: _1a3f43e9,
      name: "settings-oem-smartcar"
    }, {
      path: "oem/:id",
      component: _3f64e0a2,
      name: "settings-oem-id"
    }, {
      path: "telematics/:id",
      component: _b000fb52,
      name: "settings-telematics-id"
    }]
  }, {
    path: "/signup",
    component: _fb82fea4,
    name: "signup"
  }, {
    path: "/terms",
    component: _5df21fe1,
    name: "terms"
  }, {
    path: "/vehicles",
    component: _5f297c20,
    name: "vehicles"
  }, {
    path: "/cards/add",
    component: _3e22d24f,
    name: "cards-add"
  }, {
    path: "/contracts/add",
    component: _bc7a24e6,
    name: "contracts-add"
  }, {
    path: "/drivers/add",
    component: _346d67b7,
    name: "drivers-add"
  }, {
    path: "/contracts/:id",
    component: _6d2a8429,
    name: "contracts-id"
  }, {
    path: "/drivers/:id",
    component: _3e5736d3,
    name: "drivers-id"
  }, {
    path: "/vehicles/:id",
    component: _22b0f1ea,
    name: "vehicles-id"
  }, {
    path: "/contracts/:id/switch-card",
    component: _b506bac0,
    name: "contracts-id-switch-card"
  }, {
    path: "/contracts/:id/switch-vehicle",
    component: _412ae868,
    name: "contracts-id-switch-vehicle"
  }, {
    path: "/vehicles/:id/doors",
    component: _97c9817e,
    name: "vehicles-id-doors"
  }, {
    path: "/vehicles/:id/doors/control",
    component: _ba2736e8,
    name: "vehicles-id-doors-control"
  }, {
    path: "/vehicles/:id/doors/control-location",
    component: _437ed506,
    name: "vehicles-id-doors-control-location"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
