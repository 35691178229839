import Vue from 'vue'
import moment from 'moment'

Vue.mixin({
  methods: {
    sortByName(a, b) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    },
    sortByFirstName(a, b) {
      if (a.first_name < b.first_name)
        return -1;
      if (a.first_name > b.first_name)
        return 1;
      return 0;
    },
    sortByDriverFirstName(a, b) {
      if (a.driver_first_name < b.driver_first_name)
        return -1;
      if (a.driver_first_name > b.driver_first_name)
        return 1;
      return 0;
    },
    sortByPlateNumber(a, b) {
      if (a.plate_number < b.plate_number)
        return -1;
      if (a.plate_number > b.plate_number)
        return 1;
      return 0;
    },
    sortByReference(a, b) {
      if (a.reference < b.reference)
        return -1;
      if (a.reference > b.reference)
        return 1;
      return 0;
    },
    filterApply(results, filters){
        Object.values(filters).forEach(filter => {
                if(filter.value !== null){
                    results = typeof filter.value == "boolean" ? (filter.value ? results.filter(c => c[filter.key]!==null) : results.filter(c => c[filter.key]==null)) :  results.filter(c => c[filter.key]==filter.value);
                }
            }
        );
        return results;
    },
    filterReset(filters){
        Object.values(filters).forEach(filter => {
                filter.value = null;
            }
        );
    },
    // Display number of days spent between 2 dates
    diffDate(dateStart, dateEnd, measurement) { // moment Js Format, supported measurements are years, months, weeks, days, hours, minutes, and seconds
      dateStart.startOf('day');
      if(!dateEnd){
        dateEnd = this.$moment().startOf('day');
      }
      return dateEnd.diff(dateStart, measurement);
    },
    // Checking if a date time Period [ dateStart, dateEnd ] is inside the periode [ fromDate, toDate ]
    isDateInPeriod(fromDate, toDate, dateStart, dateEnd) { // moment Js Format
      if ((fromDate <= dateStart && dateStart <= toDate)
        || (fromDate <= dateEnd && dateEnd <= toDate)
        || (dateStart < fromDate && (dateEnd > toDate || dateEnd == null))
      ) {
        return true;
      } else {
        return false;
      }
    },
    getDatePickerPresettings() {
      let settings = {
        "Today": [
          new Date(this.$moment().startOf('day').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"))
        ],
        "Yesterday": [
          new Date(this.$moment().subtract(1, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().subtract(1, 'days').endOf('day').format("YYYY-MM-DD HH:mm:ss"))
        ],
        "This week": [
          new Date(this.$moment().startOf('isoWeek').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().endOf('isoWeek').format("YYYY-MM-DD HH:mm:ss"))
        ],
        "Last week": [
          new Date(this.$moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD HH:mm:ss"))
        ],
        "This month": [
          new Date(this.$moment().startOf('month').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"))
        ],
        "Last month": [
          new Date(this.$moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss")),
          new Date(this.$moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss"))
        ]
      }
      //console.log(settings)
      return settings
    },
    generateDailyArray(fromDate, toDate) { // moment Js Format
      var dates = [];
      fromDate.startOf('day');
      toDate.startOf('day');
      dates.push({
        date: fromDate.clone().toDate(),
        min: 9999999999999999,
        max: 0,
        diff: 0
      })
      while (fromDate.add(1, 'days').diff(toDate) <= 0) {
        dates.push({
          date: fromDate.clone().toDate(),
          min: 9999999999999999,
          max: 0,
          diff: 0
        })
      }
      //console.log('dates',dates)
      return dates;
    },
    displayContractDuration(contract){
      let now = this.$moment();
      let date_end = this.$moment(contract.date_end);
      let date_start = this.$moment(contract.date_start);

      let days = this.diffDate(date_start, (contract.date_end ? date_end : now), 'days');
      let months = this.diffDate(date_start, (contract.date_end ? date_end : now), 'months');
      //let years = this.diffDate(date_start, (contract.date_end ? date_end : now), 'years');
      
      if(months > 2){
        return months + ' months'
      } else {
        return days + ' days'
      }
    },
    contractDurationProgressPercentage(contract) {
      if(contract.date_end) {
        let percentage = 0;
        let now = this.$moment();
        let date_end = this.$moment(contract.date_end);
        let date_start = this.$moment(contract.date_start);
        if(this.diffDate(date_start, now, 'days') <= 0){
          percentage = 0
        } else if(this.diffDate(date_end, now, 'days') >= 0){
          percentage = 100
        } else {
          percentage = (this.diffDate(date_start, now, 'days') / this.diffDate(date_start, date_end, 'days'))*100;
        }
        return percentage.toFixed();
      } else {
        return null;
      }
    },
    randomInRange(min, max) {
      return Math.random() < 0.5 ? ((1-Math.random()) * (max-min) + min) : (Math.random() * (max-min) + min);
    },
    capitalizeString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    isFrenchCountryCode(country_code){
      let codes = ['FR','GP','GF','RE','MQ','YT','NC','PF','BL','MF','PM','TF','WF']
      return codes.includes(country_code)
    }
  }
})