export const state = () => ({
  url: process.env.NODE_ENV !== 'production'
  ? `http://localhost/fleetch-v2/fleetch-back` :
  `https://www.fleetch.io/backend`,
  apiUrl: process.env.NODE_ENV !== 'production'
   ? `http://localhost/fleetch-v2/fleetch-back/api/api.php?action=` :
   `https://www.fleetch.io/backend/api/api.php?action=`,
})

export const getters = {

  getUrl(state) {
    return state.url
  },

  getApiUrl(state) {
    return state.apiUrl
  },

  getTelematicsAPI(state) {
    return state.telematicsAPI
  },

  getMapAPIkey(state) {
    return state.mapAPIkey
  }

}

export const mutations = {
  setApiUrl(state, value) {
    state.apiUrl = value
  },

  setTelematicsAPI(state, value) {
    state.telematicsAPI = value
  }
}
