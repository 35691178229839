import Cookies from 'js-cookie'

export default function ({ $axios, store }) {

  $axios.onRequest((config)  => {

    const token = Cookies.get('auth._token.local');

    // Update token axios header
    if (token && token !== 'false') {
      //config.headers.common['Authorization'] = token
      $axios.setHeader('Authorization', token)
    } else {
      $axios.setHeader('Authorization', null)
    }
  })

}
