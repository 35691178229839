//
//
//
//
//
//

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
