//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  middleware: "auth",
  data() {
    return {
      menuActive: false
    };
  },
  computed: {
    ...mapState("auth", {
      user: "user",
    }),
  },
  watch: {
    $route() {
      this.menuActive = false
    },
  },
  methods: {
    contactSupport(){
      function closeChatBoxOnMobile(){
        console.log("Close chatbox")
        $crisp.push(["do", "chat:hide"])
        $crisp.push(["config", "hide:on:mobile", [true]]);
      }
      $crisp.push(['do', 'chat:show'])
      $crisp.push(["do", "chat:open"])
      $crisp.push(["on", "chat:closed", closeChatBoxOnMobile])
    }
  }
};
