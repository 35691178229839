//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  data() {
    return {
      notification: {
        detection: false,
        message: "",
        type: "",
      },
    };
  },
  mounted: function () {
    this.$root.$on("notification", (notification) => {
      this.notification = {
        detection: true,
        message: notification.message,
        type: notification.type,
      };
    });
  },
  watch: {
    $route(to, from) {
      this.notification.detection = false;
    },
  },
};
