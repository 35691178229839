import Vue from 'vue'
import moment from 'moment'

Vue.mixin({
  methods: {
    getSocRanges() {
      return [
        {
          "min": 0,
          "max": 24,
          "color": "#DC143C" // crimson
        },
        {
          "min": 25,
          "max": 49,
          "color": "#FFD700" // gold
        },
        {
          "min": 50,
          "max": 74,
          "color": "#6495ED" // cornflowerblue
        },
        {
          "min": 75,
          "max": 89,
          "color": "#3CB371" // #mediumseagreen
        },
        {
          "min": 90,
          "max": 100,
          "color": "#3CB371" // #mediumseagreen
        }
    ];
  },
  getOdometerRanges() {
      return [
        {
          "min": 0,
          "max": 15000
        },
        {
          "min": 15001,
          "max": 30000
        },
        {
          "min": 30001,
          "max": 45000
        },
        {
          "min": 45001,
          "max": 60000
        },
        {
          "min": 60001,
          "max": 999999
        }
      ];
    },
  getOdometerRangesMax(max) {
      let ranges = [];
      let increment = Math.floor(max/4);
      let temp_min = 0;
      let temp_max = 0;
      for (let i=0; i<5; i++) {
        temp_min = i==0 ? 0 : i*increment+1;
        temp_max = i==4 ? 999999 : (i+1)*increment
        ranges.push({
          "min": temp_min,
          "max": temp_max
        })
      }
      return ranges;
    },
    getOdometerRangesWeekly() {
      return [
        {
          "min": 0,
          "max": 500
        },
        {
          "min": 501,
          "max": 1000
        },
        {
          "min": 1001,
          "max": 1500
        },
        {
          "min": 1501,
          "max": 2000
        },
        {
          "min": 2001,
          "max": 9999
        }
      ];
    },
    getOdometerRangesMonthly() {
      return [
        {
          "min": 0,
          "max": 2000
        },
        {
          "min": 2001,
          "max": 3000
        },
        {
          "min": 4001,
          "max": 5000
        },
        {
          "min": 5001,
          "max": 6000
        },
        {
          "min": 6001,
          "max": 9999
        }
      ];
    },
    getOdometerRangesYearly() {
      return [
        {
          "min": 0,
          "max": 15000
        },
        {
          "min": 15001,
          "max": 30000
        },
        {
          "min": 30001,
          "max": 45000
        },
        {
          "min": 45001,
          "max": 60000
        },
        {
          "min": 60001,
          "max": 99999
        }
      ];
    },
    getContractOdometersRange(days) {
      let range = []
      switch(days){
        case 7:
          range = this.getOdometerRangesWeekly();
        break;

        case 27:
        case 29:
        case 29:
        case 30:
        case 31:
          range = this.getOdometerRangesMonthly();
        break;

        default:
          if(days>100){
            range = this.getOdometerRangesYearly();
          } else {
            var dailyIncrement = 250 // ~~ 1500 km / 7 days
            var max = days * dailyIncrement
            var step = max/5
            for (let i = 0; i < 5; i++) {
              range.push({
                "min": (i === 0 ? 0 : (i * step + 1)),
                "max": (i + 1) * step
              })
            }
          }
      }
      return range
    },
    generateChartColors(colorsPalette, dataArray) { // see palettes here : http://google.github.io/palette.js/
      var colors = palette(colorsPalette, dataArray.length);
      colors.forEach((color, key) => {
        colors[key] = "#" + color;
      });
      return colors
    },
    generateOdemeterChartData(odometers, days=null) {
      let odometerRange = days ? this.getContractOdometersRange(days) : this.getOdometerRanges()
      let newDataset = {
        labels: [],
        data: [],
        colors: []
      };
      newDataset.colors = this.generateChartColors("cb-Dark2", odometerRange)
      odometerRange.forEach((range, key) => {
        newDataset.data[key] = 0;
        if(key === (odometerRange.length-1)){
          newDataset.labels[key] = ' > ' + odometerRange[key-1].max + ' km';
        } else
          newDataset.labels[key] = range.min + ' - ' + range.max + ' km'; //(range.min / 1000).toFixed(0) + ' - ' + (range.max / 1000).toFixed(0) + ' km';
      })
      let odometerValue = 0
      odometers.forEach((odometer, key) => {
        //odometerValue = days ? odometer.data : odometer.data / 1000 // GEOTAB LEGACY
        odometerValue = odometer.data ? odometer.data : odometer
        odometerRange.forEach((range, key2) => {
          if (odometerValue >= range.min && odometerValue <= range.max) {
            newDataset.data[key2] = newDataset.data[key2] + 1;
          }
        })
      })
      return newDataset;
    },
    generateOdemeterChartDataMax(odometers, max=60000) {
      let odometerRange = this.getOdometerRangesMax(max)
      let newDataset = {
        labels: [],
        data: [],
        colors: []
      };
      newDataset.colors = this.generateChartColors("cb-Dark2", odometerRange)
      odometerRange.forEach((range, key) => {
        newDataset.data[key] = 0;
        if(key === (odometerRange.length-1)){
          newDataset.labels[key] = ' > ' + odometerRange[key-1].max + ' km';
        } else
          newDataset.labels[key] = range.min + ' - ' + range.max + ' km'; //(range.min / 1000).toFixed(0) + ' - ' + (range.max / 1000).toFixed(0) + ' km';
      })
      let odometerValue = 0
      odometers.forEach((odometer, key) => {
        //odometerValue = days ? odometer.data : odometer.data / 1000 // GEOTAB LEGACY
        odometerValue = odometer.data ? odometer.data : odometer
        odometerRange.forEach((range, key2) => {
          if (odometerValue >= range.min && odometerValue <= range.max) {
            newDataset.data[key2] = newDataset.data[key2] + 1;
          }
        })
      })
      return newDataset;
    },
    generateSocChartData(SoCs, days=null) {
      let socRange = this.getSocRanges()
      let newDataset = {
        labels: [],
        data: [],
        colors: []
      };
      socRange.forEach((range, key) => {
        newDataset.colors[key] = socRange[key].color;
        newDataset.data[key] = 0;
        if(key === (socRange.length-1)){
          newDataset.labels[key] = '≥' + socRange[key].min + '%';
        } else
          newDataset.labels[key] = range.min + '-' + range.max + '%';
      })
      let socValue = 0
      SoCs.forEach((SoC, key) => {
        socValue = SoC
        socRange.forEach((range, key2) => {
          if (socValue >= range.min && socValue <= range.max) {
            newDataset.data[key2] = newDataset.data[key2] + 1;
          }
        })
      })
      return newDataset;
    },
    generateFleetKPIs(vehicles) {
      // TOTAL FLEET SIZE
      let nbOfVehicles = vehicles.length

      // ACTIVE FLEET SIZE
      let active = this.vehicles.filter((v) => v.contract_id);
      let nbOfVehiclesActive = active.length;

      // UNACTIVE FLEET SIZE
      let nbOfVehiclesUnactive = nbOfVehicles - nbOfVehiclesActive

      // FLEET UTILIZATION RATE
      let utilizationRate = nbOfVehicles != 0
        ? (
          (nbOfVehiclesActive * 100) /
          nbOfVehicles
        ).toFixed(0)
        : 0;

      // DISCONNECTED (UNPLUGED) FLEET SIZE
      let unplugged = this.vehicles.filter((v) => !v.device_id);
      let nbOfVehiclesUnplugged = unplugged.length

      return {
        nbOfVehicles: nbOfVehicles,
        nbOfVehiclesActive: nbOfVehiclesActive,
        nbOfVehiclesUnactive: nbOfVehiclesUnactive,
        utilizationRate: utilizationRate,
        nbOfVehiclesUnplugged: nbOfVehiclesUnplugged,
      };
    },
    generateOdometerKPIs(odometers) {
      let min = 0;
      let max = 0;
      let sum = 0;
      let size = 0;
      let average = 0;
      let tmpData = 0;
      if (odometers) {
        odometers.forEach((odometer, key) => {
          // tmpData = odometer.data / 1000 // GEOTAB LEGACY
          tmpData = Number(odometer)
          min =
            min == 0
              ? tmpData
              : tmpData < min
                ? tmpData
                : min;
          max =
            max == 0
              ? tmpData
              : tmpData > max
                ? tmpData
                : max;
          sum += tmpData;
          size++;
        });
        average = size > 0 ? (sum / size) : 0;
      }
      return {
        min: min.toFixed(0),
        max: max.toFixed(0),
        average: average.toFixed(0),
      };
    },
    generateBatteryKPIs(batteryCharges) {
      let min = 0;
      let max = 0;
      let sum = 0;
      let size = 0;
      let average = 0;
      let tmpData = 0;
      if (batteryCharges) {
        batteryCharges.forEach((batteryCharge, key) => {
          // tmpData = batteryCharge.data // GEOTAB LEGACY
          tmpData = batteryCharge
          min =
            min == 0
              ? tmpData
              : tmpData < min
                ? tmpData
                : min;
          max =
            max == 0
              ? tmpData
              : tmpData > max
                ? tmpData
                : max;
          sum += tmpData;
          size++;
        });
        average = size > 0 ? (sum / size) : 0;
      }
      return {
        min: min.toFixed(0),
        max: max.toFixed(0),
        average: average.toFixed(0),
      };
    },
    getDailyMetrics(metrics, fromDate, toDate) {
      //console.log("getDailyMetrics ACTIVE")
      let initialFromDate = fromDate.clone()
      let initialToDate = toDate.clone()

      //console.log('metrics',metrics)
      let tmpDate = null
      //let dates = this.generateDailyArray(fromDate, toDate);
      let output = {
        devices: [],
        sum: 0
      }
      // Calculating diff per Device
      metrics.forEach((device, key) => {
        //console.log('device',device.device)
        output.devices.push({
          device: device.device,
          values: device.values,
          min: 9999999999999999,
          max: 0,
          diff: 0
        })
        device.values.forEach(metric => {
          output.devices[key].min = Math.min(output.devices[key].min, metric.data)
          output.devices[key].max = Math.max(output.devices[key].max, metric.data)
        })
      })
      // Calculating diff per Device
      output.devices.forEach(device => {
        device.diff = device.max - device.min
      })
      // Calculating global stats
      output.devices.forEach(device => {
        device['calendar'] = this.generateDailyArray(fromDate.clone(), toDate.clone())
        output.sum += device.diff
      })
      //console.log('output after generating calendars',output)

      // Generating Daily Calendar per device
      output.devices.forEach(device => {
        //console.log('///////// DEVICE = '+device.device+' /////////////')
        device.values.forEach(metric => {
          tmpDate = this.$moment(metric.dateTime)
          device.calendar.forEach(day => {
            if (tmpDate.isSame(day.date, 'day')) {   //tmpDate.diff(day.date, 'days') == 0)
              day.min = Math.min(day.min, metric.data)
              day.max = Math.max(day.max, metric.data)
              day.diff = day.max - day.min
            }
          })
        })
      })

      // Cleaning
      /*
      output.devices.forEach(device => {
        if(device.min == device.max){
          device.diff = 0
        }
      })
      //*/

      // Generating global Daily Calendar
      output['calendar'] = this.generateDailyArray(fromDate.clone(), toDate.clone())
      output.calendar.forEach((day, key) => {
        output.devices.forEach(device => {
          day.min = Math.min(day.min, device.calendar[key].min)
          day.max = Math.max(day.max, device.calendar[key].max)
          day.diff += device.calendar[key].diff
        })
      })

      // Cleaning
      //*
      output.calendar.forEach((day, key) => {
        if(day.min == day.max) day.diff = 0
        if(day.min == 9999999999999999) day.min = 0
      })
      //*/

      //console.log('output after final calculation', output)
      return output
    },
    getMultipleVechiclesOdometers(vehicles, fromDate, toDate) {
      //console.log('getMultipleVechiclesOdometers ACTIVE')
      let isLoading = true;

      //var deviceId = this.contract.api_id;
      const authentication = {
        credentials: {
          database: "LesVoituresVertes",
          userName: "api@unicarn.eu",
          password: "1LoveGeot@b!",
        },
        path: "my.geotab.com",
      };
      const api = new GeotabApi(authentication);

      //* INITIALIZE LIST OF DEVICES

      var devices = []
      vehicles.forEach((vehicle, key) => {
        if (vehicle.device)
          devices.push(vehicle.device)
      })
      //console.log("devices", devices)

      //* RETRIEVE SOME METRICS
      var calls = [];

      devices.forEach((device, key) => {
        calls.push([
          "Get",
          {
            typeName: "StatusData",
            search: {
              deviceSearch: {
                id: device.api_id,
              },
              diagnosticSearch: {
                id: "DiagnosticOdometerId",
              },
              fromDate: fromDate,
              toDate: toDate,
            },
            resultsLimit: 3000,
          },
        ]);
      });
      //console.log("calls",calls)

      api
        .multiCall(calls)
        .then((data) => {
          isLoading = false;
          let metrics = []
          data.forEach((metric) => {
            metric.forEach((diagnostic) => {
              if (!metrics[diagnostic['device']['id']]) {
                metrics[diagnostic['device']['id']] = []
              }
              metrics[diagnostic['device']['id']] = diagnostic
            })
          })
          //console.log('metrics', metrics)
          return metrics
        })
        .catch((error) => {
          //console.log(error)
          isLoading = false;
        });
      //*/
    }
  }
})
