import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=0c6b0a32&"
import script from "./auth.vue?vue&type=script&lang=js&"
export * from "./auth.vue?vue&type=script&lang=js&"
import style0 from "./auth.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/vercel/path0/components/Logo.vue').default,Header: require('/vercel/path0/components/Header.vue').default,NotificationPanel: require('/vercel/path0/components/NotificationPanel.vue').default})
