export default () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "7d33871d-6e97-447b-9d1f-2f2d702d0077";
  
    (function() {
      var d = document;
      var s = d.createElement("script");
  
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  };